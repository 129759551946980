body {
  background-color: gainsboro;
  padding: 20px;
  margin: 0;
  width: 100%;
  overflow-x: hidden;
}

h1, h2, p, ul, li {
  font-family: sans-serif;
}

ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  padding: 0;
 
}
ul.header li a {
  color: black;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
  position: relative;
  left: 50em;
  top: -2em;
  border-radius: 5px;
}
.content {
  color: burlywood;
  padding: 20px;
  position: relative;
 
}

.content h3 {
  color: burlywood;
  padding: 20px;
  position: relative;
  top: 2em;
 
}

.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
 
}
.active {
  background-color: #0099FF;
}

.logo{
   position: relative;
   left: 4em;
   bottom: -1em;
   width: 60px;
   height: 60px;
}

.phone1{
  top: 77.15em;
  position: relative;
  left: 32em;
  color: green; 
}
.phone2{
  top: 75.2em;
  position: relative;
  left: 41em;
  color: green; 
}
h1{
  font-size: 20px
 
}
.email{
   position: relative;
   display: inline;
   top: 73em;
  left: 15em;
}
.font-link {
  font-family:'Oxygen', sans-serif;
  font-family: 'Source Code Pro', monospace;
  font-family: 'Oswald', sans-serif;
}


@media screen and (max-width: 600px) {

  .contentcontainer{
     overflow: hidden;
  }
  ul.header li a{
    display: flex; 
    position: relative;
    left: 5em;
    padding: 20px;
  
  }
  .header{
    position: relative;
    left: -2em;
    bottom: -3em;
  }
  .logo{
    bottom: 1px;
  }

  .content h3 {
  color: burlywood;
  padding: 20px;
  position: relative;
  top: 2em;
 
}

.phone1{
 top: 80em;
  position: relative;
  left: 2em;
  color: burlywood;
}
.phone2{
 top: 78em;
  position: relative;
  left: 10.5em;
  color: burlywood;
}
.email{
   position: relative;
   display: inline;
   top: 74.5em;
  left: 2em;
}
  
h1{
  font-size: 20px
 
}
 
}
@media screen 
  and (device-width: 500px)  {

    ul.header li a{
      display: flex; 
      position: relative;
      left: 3.5em;
      padding: 15px;
         
    }
    .header{
      position: relative;
      left: -4em;
      bottom: -2em;
    }
    .logo{
      bottom: 1px;
    }

}
