body {
  background-color: gainsboro;
  padding: 20px;
  margin: 0;
}
h1, h2, p, ul, li {
  font-family: sans-serif;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  padding: 0;
  
}
ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
  position: relative;
  left: 50em;
  top: -2em;
}
.content {
  background-color: gainsboro;
  padding: 20px;
  
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}
.active {
  background-color: #0099FF;
}
.logo{
   position: relative;
   margin-left: 2em;
   color: azure;
   bottom: -1em;
}

@media screen and (max-width: 600px) {
  ul.header li a{
    display: flex; 
    position: relative;
    left: 2em;
    padding: 20px;
       
  }

  .logo{
    bottom: 1em;
  }

  
}

